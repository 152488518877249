// extracted by mini-css-extract-plugin
export var bodyBase = "team-tab-module--body-base--8aecf team-tab-module--site-font--82690";
export var bodyLarge = "team-tab-module--body-large--6be37 team-tab-module--body-base--8aecf team-tab-module--site-font--82690";
export var bodyLargeBold = "team-tab-module--body-large-bold--fd6d6 team-tab-module--body-base--8aecf team-tab-module--site-font--82690";
export var bodyRegular = "team-tab-module--body-regular--fdd33 team-tab-module--body-base--8aecf team-tab-module--site-font--82690";
export var bodyRegularBold = "team-tab-module--body-regular-bold--fda34 team-tab-module--body-base--8aecf team-tab-module--site-font--82690";
export var bodySmall = "team-tab-module--body-small--79f29 team-tab-module--body-base--8aecf team-tab-module--site-font--82690";
export var bodySmallBold = "team-tab-module--body-small-bold--27d0d team-tab-module--body-base--8aecf team-tab-module--site-font--82690";
export var borderTop = "team-tab-module--border-top--91f37";
export var breakWordContainer = "team-tab-module--break-word-container--1ca32";
export var buttonIconBase = "team-tab-module--button-icon-base--7de98";
export var clickLink = "team-tab-module--click-link--81d76";
export var dropdownBase = "team-tab-module--dropdown-base--0b4f9";
export var dropdownSelectBase = "team-tab-module--dropdown-select-base--a3fcd team-tab-module--text-input--33a81";
export var flexCol = "team-tab-module--flex-col--fe628";
export var formErrorMessage = "team-tab-module--form-error-message--0d6f9";
export var h3 = "team-tab-module--h3--7c31e";
export var h4 = "team-tab-module--h4--d23f9";
export var headerContainer = "team-tab-module--headerContainer--7192d";
export var hoverLink = "team-tab-module--hover-link--e6196";
export var hoverRow = "team-tab-module--hover-row--2b141";
export var membershipContainer = "team-tab-module--membership-container--dfb86 team-tab-module--flex-col--fe628 team-tab-module--primary-border--cac79";
export var membershipHeader = "team-tab-module--membership-header--fb6ca";
export var membershipHeading = "team-tab-module--membership-heading--ea024";
export var membershipLabel = "team-tab-module--membership-label--ad538";
export var moreFiltersBorderClass = "team-tab-module--more-filters-border-class--c4898";
export var pageBg = "team-tab-module--page-bg--3cba2";
export var pointer = "team-tab-module--pointer--6ce57";
export var primaryBorder = "team-tab-module--primary-border--cac79";
export var shadowBoxLight = "team-tab-module--shadow-box-light--57269";
export var siteFont = "team-tab-module--site-font--82690";
export var slideDownAndFade = "team-tab-module--slideDownAndFade--a3b45";
export var slideLeftAndFade = "team-tab-module--slideLeftAndFade--d76dd";
export var slideRightAndFade = "team-tab-module--slideRightAndFade--f4970";
export var slideUpAndFade = "team-tab-module--slideUpAndFade--21bbb";
export var statusDecoration = "team-tab-module--status-decoration--aa533";
export var textInput = "team-tab-module--text-input--33a81";
export var textInverted = "team-tab-module--text-inverted--af739";
export var textMediumDark = "team-tab-module--text-medium-dark--ba040";
export var tooltipFont = "team-tab-module--tooltipFont--53968";
export var unstyledButton = "team-tab-module--unstyled-button--3da19";