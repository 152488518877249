import React, { HTMLProps } from 'react';

import cx from 'classnames';
import { SpacingProps, useSpacing } from 'src/hooks/spacing';

import * as styles from './input-label.module.less';

export interface InputProps extends Omit<HTMLProps<HTMLLabelElement>, 'size'>, SpacingProps {
  size?: 'xs' | 'sm' | 'md' | 'lg';
  className?: string;
}

const sizeClassMap = { xs: 'extraSmall', sm: 'small', md: 'medium', lg: 'large' };

const InputLabel: React.FC<InputProps> = ({ size = 'sm', spacing, children, className, ...props }) => {
  const spacingClass = useSpacing(spacing);
  return (
    <label {...props} className={cx(styles.inputLabel, spacingClass, className)}>
      {children}
    </label>
  );
};

export default InputLabel;
