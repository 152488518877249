// extracted by mini-css-extract-plugin
export var bodyBase = "add-role-dialog-module--body-base--0a673 add-role-dialog-module--site-font--87f9c";
export var bodyLarge = "add-role-dialog-module--body-large--8085d add-role-dialog-module--body-base--0a673 add-role-dialog-module--site-font--87f9c";
export var bodyLargeBold = "add-role-dialog-module--body-large-bold--5afd5 add-role-dialog-module--body-base--0a673 add-role-dialog-module--site-font--87f9c";
export var bodyRegular = "add-role-dialog-module--body-regular--6afe6 add-role-dialog-module--body-base--0a673 add-role-dialog-module--site-font--87f9c";
export var bodyRegularBold = "add-role-dialog-module--body-regular-bold--7a749 add-role-dialog-module--body-base--0a673 add-role-dialog-module--site-font--87f9c";
export var bodySmall = "add-role-dialog-module--body-small--5083d add-role-dialog-module--body-base--0a673 add-role-dialog-module--site-font--87f9c";
export var bodySmallBold = "add-role-dialog-module--body-small-bold--6ae9f add-role-dialog-module--body-base--0a673 add-role-dialog-module--site-font--87f9c";
export var borderTop = "add-role-dialog-module--border-top--ba3ad";
export var breakWordContainer = "add-role-dialog-module--break-word-container--a93c5";
export var buttonIconBase = "add-role-dialog-module--button-icon-base--2ab21";
export var clickLink = "add-role-dialog-module--click-link--dadcd";
export var dropdownBase = "add-role-dialog-module--dropdown-base--85884";
export var dropdownSelectBase = "add-role-dialog-module--dropdown-select-base--d7f3e add-role-dialog-module--text-input--74e58";
export var flexCol = "add-role-dialog-module--flex-col--dbb24";
export var formErrorMessage = "add-role-dialog-module--form-error-message--b810b";
export var h3 = "add-role-dialog-module--h3--d9fc0";
export var h4 = "add-role-dialog-module--h4--7b59d";
export var hoverLink = "add-role-dialog-module--hover-link--f9931";
export var hoverRow = "add-role-dialog-module--hover-row--62cbe";
export var membershipContainer = "add-role-dialog-module--membership-container--a91a7 add-role-dialog-module--flex-col--dbb24 add-role-dialog-module--primary-border--b622f";
export var membershipHeader = "add-role-dialog-module--membership-header--db01a";
export var membershipHeading = "add-role-dialog-module--membership-heading--fb69b";
export var membershipLabel = "add-role-dialog-module--membership-label--19ff5";
export var moreFiltersBorderClass = "add-role-dialog-module--more-filters-border-class--4adb7";
export var pageBg = "add-role-dialog-module--page-bg--0d5e1";
export var pointer = "add-role-dialog-module--pointer--588fe";
export var primaryBorder = "add-role-dialog-module--primary-border--b622f";
export var shadowBoxLight = "add-role-dialog-module--shadow-box-light--45840";
export var siteFont = "add-role-dialog-module--site-font--87f9c";
export var slideDownAndFade = "add-role-dialog-module--slideDownAndFade--8e3f4";
export var slideLeftAndFade = "add-role-dialog-module--slideLeftAndFade--e8230";
export var slideRightAndFade = "add-role-dialog-module--slideRightAndFade--d5e70";
export var slideUpAndFade = "add-role-dialog-module--slideUpAndFade--0fc08";
export var statusDecoration = "add-role-dialog-module--status-decoration--387e9";
export var textInput = "add-role-dialog-module--text-input--74e58";
export var textInverted = "add-role-dialog-module--text-inverted--b0e2d";
export var textMediumDark = "add-role-dialog-module--text-medium-dark--681c7";
export var tooltipFont = "add-role-dialog-module--tooltipFont--4381b";
export var unstyledButton = "add-role-dialog-module--unstyled-button--a91ea";