// TODO: create global types - probably will be done automatically by graphql apollo
export enum RoleNames {
  President = 'president',
  TennisDirector = 'tennis director',
  GeneralManager = 'general manager',
  MembershipCoordinator = 'membership co-ordinator',
  HeadCoach = 'head coach',
  Coach = 'coach',
  AssistantCoach = 'assistant coach',
  None = '',
}

export enum AdminRightNames {
  SuperAdministrator = 'super administrator',
  Administrator = 'administrator',
  MembershipAdministrator = 'membership administrator',
  CoachingAdministrator = 'coaching administrator',
  ContactsAdministrator = 'contacts administrator',
  WebsiteAdministrator = 'website administrator',
  BookingAdministrator = 'booking administrator',
}

export enum RoleStatuses {
  Active = 'active',
  Archived = 'archived',
}

export type Role = {
  id: any;
  name: string;
  status: RoleStatuses;
  description: string;
};

export type AdminRights = {
  id: any;
  name: AdminRightNames;
};

const createData = (id, name: RoleNames, status: RoleStatuses, description: string) => {
  return { id, name, status, description };
};

export const addRoleInitialData: Role = {
  id: '',
  name: '',
  status: RoleStatuses.Active,
  description: '',
};

// mocked roles data
export const allRoles: Role[] = [
  createData(
    1,
    RoleNames.President,
    RoleStatuses.Active,
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet.',
  ),
  createData(
    2,
    RoleNames.GeneralManager,
    RoleStatuses.Active,
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo.',
  ),
  createData(
    3,
    RoleNames.MembershipCoordinator,
    RoleStatuses.Active,
    'Proin sodales pulvinar sic tempor. Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate.',
  ),
  createData(
    4,
    RoleNames.TennisDirector,
    RoleStatuses.Archived,
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet.',
  ),
  createData(
    5,
    RoleNames.HeadCoach,
    RoleStatuses.Active,
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo.',
  ),
  createData(
    6,
    RoleNames.Coach,
    RoleStatuses.Active,
    'Proin sodales pulvinar sic tempor. Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate.',
  ),
  createData(
    7,
    RoleNames.AssistantCoach,
    RoleStatuses.Active,
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet.',
  ),
];

// mocked admin rights
export const allAdminRights: AdminRights[] = [
  {
    id: 1,
    name: AdminRightNames.SuperAdministrator,
  },
  {
    id: 2,
    name: AdminRightNames.Administrator,
  },
  {
    id: 3,
    name: AdminRightNames.MembershipAdministrator,
  },
  {
    id: 4,
    name: AdminRightNames.CoachingAdministrator,
  },
  {
    id: 5,
    name: AdminRightNames.ContactsAdministrator,
  },
  {
    id: 6,
    name: AdminRightNames.WebsiteAdministrator,
  },
  {
    id: 7,
    name: AdminRightNames.BookingAdministrator,
  },
];
