import { AdminRights, Role, RoleNames, RoleStatuses } from 'src/utils/typedefs/role';

// TODO: should registered be type Enum?
export type TeamMemberType = {
  id: any;
  firstName: string;
  lastName: string;
  emailAddress: string;
  roles: Role[];
  registered: string;
};

export enum AddTeamMemberActiveSteps {
  ContactDetails,
  RolesAndResponsibilities,
  AdminRights,
  Done,
}

export type AddTeamMemberVisitedSteps = {
  contactDetails: boolean;
  rolesAndResponsibilities: boolean;
  adminRights: boolean;
};

export enum HowToAddContact {
  LookUpExistingContacts = 'look up existing contacts',
  AddNewContact = 'add a new contact',
}

// TODO: extract to the global types (apollo graphql?)
export enum GenderBasic {
  Female = 'female',
  Male = 'male',
}

// TODO: check with Richard what is Member, what is Contact
export type Contact = {
  firstName: string;
  lastName: string;
  email: string;
  gender: GenderBasic;
  dateOfBirth?: Date;
  phone: string;
};

export type ContactDetails = {
  howToAddContact: HowToAddContact;
  contact: Contact;
};

export type RoleNameCheckboxes = {
  president: boolean;
  'tennis director': boolean;
  'general manager': boolean;
  'membership co-ordinator': boolean;
  'head coach': boolean;
  coach: boolean;
  'assistant coach': boolean;
};

export type AdminRightsCheckboxes = {
  'super administrator': boolean;
  administrator: boolean;
  'membership administrator': boolean;
  'coaching administrator': boolean;
  'contacts administrator': boolean;
  'website administrator': boolean;
  'booking administrator': boolean;
};

export type AddTeamMemberState = {
  contactDetails: ContactDetails;
  rolesAndResponsibilities: RoleNameCheckboxes;
  adminRights: AdminRightsCheckboxes;
};

export const addTeamMemberInitialValues: AddTeamMemberState = {
  contactDetails: {
    howToAddContact: HowToAddContact.LookUpExistingContacts,
    contact: {
      firstName: '',
      lastName: '',
      email: '',
      gender: GenderBasic.Female,
      dateOfBirth: undefined,
      phone: '',
    },
  },
  rolesAndResponsibilities: {
    president: false,
    'tennis director': false,
    'general manager': false,
    'membership co-ordinator': false,
    'head coach': false,
    coach: false,
    'assistant coach': false,
  },
  adminRights: {
    'super administrator': false,
    administrator: false,
    'membership administrator': false,
    'coaching administrator': false,
    'contacts administrator': false,
    'website administrator': false,
    'booking administrator': false,
  },
};

export const addTeamMemberVisitedSteps: AddTeamMemberVisitedSteps = {
  contactDetails: true,
  rolesAndResponsibilities: false,
  adminRights: false,
};

const createData = (
  id,
  firstName: string,
  lastName: string,
  emailAddress: string,
  roles: Role[],
  registered: string,
) => {
  return { id, firstName, lastName, emailAddress, roles, registered };
};

export const allTeams: TeamMemberType[] = [
  createData(
    1,
    'William',
    'Low',
    'will.low@clubspark.com',
    [
      {
        id: 1,
        name: RoleNames.President,
        status: RoleStatuses.Active,
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet.',
      },
      {
        id: 2,
        name: RoleNames.GeneralManager,
        status: RoleStatuses.Active,
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo.',
      },
    ],
    'Yes',
  ),
  createData(
    2,
    'Derek',
    'Brewer',
    'derek@email.com',
    [
      {
        id: 3,
        name: RoleNames.MembershipCoordinator,
        status: RoleStatuses.Active,
        description:
          'Proin sodales pulvinar sic tempor. Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate.',
      },
    ],
    'Yes',
  ),
  createData(
    3,
    'Francis',
    'Copping',
    'francis@email.com',
    [
      {
        id: 4,
        name: RoleNames.TennisDirector,
        status: RoleStatuses.Archived,
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet.',
      },
    ],
    'Invited Nov 13 2019',
  ),
  createData(
    4,
    'Hugo',
    'Lloris',
    'hugo@email.com',
    [
      {
        id: 5,
        name: RoleNames.Coach,
        status: RoleStatuses.Active,
        description:
          'Proin sodales pulvinar sic tempor. Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate.',
      },
    ],
    'Invited Nov 13 2019',
  ),
];
