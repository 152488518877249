import React, { useMemo } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import AdminTable, { DataCols } from 'src/components/admin-table/admin-table';
import Button from 'src/components/button/button';
import CustomDialog from 'src/components/custom-dialog/custom-dialog';
import ExpansionList from 'src/components/expansion-list/expansion-list';
import Icon from 'src/components/icon/icon';
import Panel from 'src/components/panel/panel';
import AddRoleDialog from 'src/components/role-dialog/add-role-dialog';
import TablePanelTitle from 'src/components/table-panel-title/table-panel-title';
import { PageMaxWidth } from 'src/components/util-components/util-components';
import { allRoles, Role } from 'src/utils/typedefs/role';

import * as styles from './role-tab.module.less';

const RoleTab: React.FC = () => {
  const { t } = useTranslation();
  const [openAddRole, setOpenAddRole] = React.useState(false);

  const handleAddRoleClick = () => {
    setOpenAddRole(true);
  };

  const handleAddRoleClose = () => {
    setOpenAddRole(false);
  };

  return (
    <PageMaxWidth>
      <Panel>
        <Grid container={true} justifyContent="space-between" className={styles.headerContainer}>
          <TablePanelTitle title={t('roles and responsibilities')} />
          <Button onClick={handleAddRoleClick}>
            {<Icon name="sm-add" light={true} />} {t('add role')}
          </Button>
        </Grid>

        {/* TODO: refactor dialog in order to work with formik form */}
        {/* TODO: submit form is not working */}
        <CustomDialog
          open={openAddRole}
          onClose={handleAddRoleClose}
          title={t('add role')}
          content={<AddRoleDialog />}
          actions={
            <>
              <Button onClick={handleAddRoleClose}>{t('close')}</Button>
              <Button type="submit">{t('confirm')}</Button>
            </>
          }
        />

        <Grid item xs={12}>
          <RoleTable roles={allRoles} />
        </Grid>
      </Panel>
    </PageMaxWidth>
  );
};

interface RoleTableProps {
  roles: Role[];
}

const RoleTable: React.FC<RoleTableProps> = ({ roles }) => {
  const { t } = useTranslation();

  const cols: DataCols<Role> = useMemo(
    () => [
      { key: 'name', title: t('name'), getValue: (role) => t(role.name) },
      { key: 'status', title: t('status'), getValue: (role) => t(role.status) },
      { key: 'description', title: t('description'), getValue: (role) => t(role.description) },
    ],
    [],
  );

  return (
    <>
      <Box display={{ xs: 'none', sm: 'block' }}>
        <AdminTable columns={cols} data={roles} />
      </Box>
      <Box display={{ xs: 'block', sm: 'none' }}>
        <ExpansionList columns={cols} data={roles} />
      </Box>
    </>
  );
};

export default RoleTab;
