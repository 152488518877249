import React, { useMemo } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import AdminTable, { DataCols } from 'src/components/admin-table/admin-table';
import Button from 'src/components/button/button';
import ChipSquared from 'src/components/chip-squared/chip-squared';
import ExpansionList from 'src/components/expansion-list/expansion-list';
import Icon from 'src/components/icon/icon';
import Panel from 'src/components/panel/panel';
import TablePanelTitle from 'src/components/table-panel-title/table-panel-title';
import { PageMaxWidth } from 'src/components/util-components/util-components';
import { allTeams, TeamMemberType } from 'src/utils/typedefs/team';

import * as styles from './team-tab.module.less';

const TeamTab: React.FC = () => {
  const { t } = useTranslation();

  const handleNavigate = () => {
    navigate('/college/team/add-team-member');
  };

  return (
    <PageMaxWidth>
      <Panel>
        <Grid container={true} justifyContent="space-between" className={styles.headerContainer}>
          <TablePanelTitle title={t('team')} />
          <Button onClick={handleNavigate}>
            {<Icon name="sm-add" light={true} />} {t('team member')}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <TeamHomeTable teams={allTeams} />
        </Grid>
      </Panel>
    </PageMaxWidth>
  );
};

interface TeamHomeTableProps {
  teams: TeamMemberType[];
}

const TeamHomeTable: React.FC<TeamHomeTableProps> = ({ teams }) => {
  const { t } = useTranslation();

  const cols: DataCols<TeamMemberType> = useMemo(
    () => [
      { key: 'firstName', title: t('first name'), getValue: (teamMember) => teamMember.firstName },
      { key: 'lastName', title: t('last name'), getValue: (teamMember) => teamMember.lastName },
      {
        key: 'emailAddress',
        title: t('email address'),
        getValue: (teamMember) => teamMember.emailAddress,
      },
      {
        key: 'roles',
        title: t('roles'),
        getValue: (teamMember) => teamMember.roles.map((role) => <ChipSquared key={role.id} label={t(role.name)} />),
      },
      { key: 'registered', title: t('registered'), getValue: (teamMember) => teamMember.registered },
    ],
    [],
  );

  return (
    <>
      <Box display={{ xs: 'none', sm: 'block' }}>
        <AdminTable columns={cols} data={teams} />
      </Box>
      <Box display={{ xs: 'block', sm: 'none' }}>
        <ExpansionList columns={cols} data={teams} />
      </Box>
    </>
  );
};

export default TeamTab;
