import React from 'react';

import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import RoleTab from 'src/components/role-tab/role-tab';
import TabPanel from 'src/components/tab-panel/tab-panel';
import Tabs from 'src/components/tabs/tabs';
import TeamTab from 'src/components/team-tab/team-tab';

const TeamTabs: React.FC = () => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Tabs value={value} labels={[t('team'), t('roles')]} handleChange={handleChange} />
      <Grid item xs={12}>
        <TabPanel value={value} index={0}>
          <TeamTab />
        </TabPanel>
      </Grid>
      <Grid item xs={12}>
        <TabPanel value={value} index={1}>
          <RoleTab />
        </TabPanel>
      </Grid>
    </>
  );
};

export default TeamTabs;
